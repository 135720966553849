import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/blog/Blog.vue')
  },
  {
    path: '/blog/15-samyh-vostrebovannyh-professij-vo-francii-po-versii-platformy-linkedIn',
    name: 'BlogPost1',
    component: () => import(/* webpackChunkName: "blogPost1" */ '../views/blog/posts/Post1.vue')
  },
  {
    path: '/blog/besplatnaja-magistratura-vo-francii',
    name: 'BlogPost2',
    component: () => import(/* webpackChunkName: "blogPost2" */ '../views/blog/posts/Post2.vue')
  },
  {
    path: '/blog/vyshee-obrazovanie-vo-francii',
    name: 'BlogPost3',
    component: () => import(/* webpackChunkName: "blogPost3" */ '../views/blog/posts/Post3.vue')
  },
  {
    path: '/blog/pochemu-stoit-otkryt-biznes-vo-francii',
    name: 'BlogPost4',
    component: () => import(/* webpackChunkName: "blogPost4" */ '../views/blog/posts/Post4.vue')
  },
  {
    path: '/blog/sistema-obrazovanija-vo-francii',
    name: 'BlogPost5',
    component: () => import(/* webpackChunkName: "blogPost5" */ '../views/blog/posts/Post5.vue')
  },
  {
    path: '/cities',
    name: 'Cities',
    component: () => import(/* webpackChunkName: "cities" */ '../views/city/Cities.vue')
  },
  {
    path: '/cities/reims',
    name: 'Reims',
    component: () => import(/* webpackChunkName: "reims" */ '../views/city/Reims.vue')
  },
  {
    path: '/cities/lille',
    name: 'Lille',
    // route level code-splitting
    // this generates a separate chunk (lille.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lille" */ '../views/city/Lille.vue')
  },
  {
    path: '/cities/montpellier',
    name: 'Montpellier',
    component: () => import(/* webpackChunkName: "montpellier" */ '../views/city/Montpellier.vue')
  },
  {
    path: '/cities/paris',
    name: 'Paris',
    component: () => import(/* webpackChunkName: "paris" */ '../views/city/Paris.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
  },
  {
    path: '/schools',
    name: 'Schools',
    component: () => import(/* webpackChunkName: "schools" */ '../views/schools/Schools.vue')
  },
  {
    path: '/schools/business-schools',
    name: 'BusinessSchools',
    component: () => import(/* webpackChunkName: "businesseschools" */ '../views/categories/Schools-business.vue')
  },
  {
    path: '/schools/business-schools/skema',
    name: 'Skema',
    component: () => import(/* webpackChunkName: "skema" */ '../views/schools/business-schools/Skema.vue')
  },
  {
    path: '/schools/business-schools/neoma',
    name: 'Neoma',
    component: () => import(/* webpackChunkName: "Neoma" */ '../views/schools/business-schools/Neoma.vue')
  },
  {
    path: '/schools/business-schools/ipag',
    name: 'Ipag',
    component: () => import(/* webpackChunkName: "Ipag" */ '../views/schools/business-schools/Ipag.vue')
  },
  {
    path: '/schools/business-schools/mbs',
    name: 'Mbs',
    component: () => import(/* webpackChunkName: "Mbs" */ '../views/schools/business-schools/Mbs.vue')
  },
  {
    path: '/schools/business-schools/pbs',
    name: 'Pbs',
    component: () => import(/* webpackChunkName: "Pbs" */ '../views/schools/business-schools/Pbs.vue')
  },
  {
    path: '/schools/language-schools',
    name: 'LanguageSchools',
    component: () => import(/* webpackChunkName: "languageschools" */ '../views/categories/Schools-language.vue')
  },
  {
    path: '/schools/language-schools/accord',
    name: 'Accord',
    component: () => import(/* webpackChunkName: "Accord" */ '../views/schools/language-schools/Accord.vue')
  },
  {
    path: '/schools/language-schools/france-langue',
    name: 'France',
    component: () => import(/* webpackChunkName: "France" */ '../views/schools/language-schools/France.vue')
  },
  {
    path: '/schools/fashion-schools',
    name: 'FashionSchools',
    component: () => import(/* webpackChunkName: "fashionschools" */ '../views/categories/Schools-fashion.vue')
  },
  {
    path: '/schools/fashion-schools/istituto-marangoni',
    name: 'Maragoni',
    component: () => import(/* webpackChunkName: "Maragoni" */ '../views/schools/fashion-schools/Maragoni.vue')
  },
  {
    path: '/schools/fashion-schools/strate',
    name: 'Strate',
    component: () => import(/* webpackChunkName: "Strate" */ '../views/schools/fashion-schools/Strate.vue')
  },
  {
    path: '/schools/fashion-schools/lisaa',
    name: 'Lisaa',
    component: () => import(/* webpackChunkName: "Lisaa" */ '../views/schools/fashion-schools/Lisaa.vue')
  },
  {
    path: '/schools/fashion-schools/itm',
    name: 'Itm',
    component: () => import(/* webpackChunkName: "Itm" */ '../views/schools/fashion-schools/Itm.vue')
  },
  {
    path: '/schools/fashion-schools/iesa',
    name: 'Iesa',
    component: () => import(/* webpackChunkName: "Iesa" */ '../views/schools/fashion-schools/Iesa.vue')
  },
  {
    path: '/schools/fashion-schools/cours-florent',
    name: 'CoursFlorent',
    component: () => import(/* webpackChunkName: "CoursFlorent" */ '../views/schools/fashion-schools/CoursFlorent.vue')
  },
  {
    path: '/schools/fashion-schools/clcf',
    name: 'Clcf',
    component: () => import(/* webpackChunkName: "Clcf" */ '../views/schools/fashion-schools/Clcf.vue')
  },
  {
    path: '/schools/fashion-schools/atelier-chardon-savard',
    name: 'AtelierChardon',
    component: () => import(/* webpackChunkName: AtelierChardon" */ '../views/schools/fashion-schools/AtelierChardon.vue')
  },
  {
    path: '/schools/culinary-schools',
    name: 'CulinarySchools',
    component: () => import(/* webpackChunkName: "culinary" */ '../views/categories/Schools-culinary.vue')
  },
  {
    path: '/schools/culinary-schools/ducasse',
    name: 'Ducasse',
    component: () => import(/* webpackChunkName: "Ducasse" */ '../views/schools/culinary-schools/Ducasse.vue')
  },
  {
    path: '/schools/culinary-schools/cordon-bleu',
    name: 'Cordon',
    component: () => import(/* webpackChunkName: "Cordon" */ '../views/schools/culinary-schools/Cordon.vue')
  },
  {
    path: '/schools/engineer-schools',
    name: 'EngineerSchools',
    component: () => import(/* webpackChunkName: "engineerschools" */ '../views/categories/Schools-engineer.vue')
  },
  {
    path: '/schools/engineer-schools/cy',
    name: 'Cy',
    component: () => import(/* webpackChunkName: "Cy" */ '../views/schools/engineer-schools/Cy.vue')
  },
  {
    path: '/schools/web-schools',
    name: 'WebSchools',
    component: () => import(/* webpackChunkName: "webschools" */ '../views/categories/Schools-web.vue')
  },
  {
    path: '/tests/readiness',
    name: 'Readiness',
    component: () => import(/* webpackChunkName: "readiness" */ '../views/tests/Readiness.vue')
  },
  {
    path: '/tests/programs',
    name: 'ProgramsChoice',
    component: () => import(/* webpackChunkName: "testsprograms" */ '../views/tests/programsChoice.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top:0 }
  }
})

export default router
