import firebase from './Firebase';
import { getDatabase, push, ref, set } from "firebase/database";

const database = getDatabase(firebase);

export async function writeUserData(dataPassed) {
   try {
    validationData(dataPassed);
   } catch (error) {
    console.log(error)
   }
    
  push(ref(database, 'users/'), dataPassed);
}

function validationData(requestObject){
    if(!requestObject){
        throw new Error('invalid request Object');
    }
    if(!requestObject.age || !requestObject.nationality || !requestObject.french || !requestObject.budget || !requestObject.education || !requestObject.name || !requestObject.english || !requestObject.phone){
        throw new Error('invalid data provided');
    }
}

