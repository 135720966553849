<template>
  <div class="index">
    <Navbar/>  
    <Presentation/>
    <Explore/>
    <Speciality/>
    <Feedback/> 
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import Presentation from '@/components/index/Presentation.vue'
import Explore from '@/components/index/Explore.vue'
import Speciality from '@/components/index/Speciality.vue'
import Feedback from '@/components/index/Feedback.vue'

export default {
  name: 'Home',
  components: {
    Navbar,
    Presentation,
    Explore,
    Speciality,
    Feedback
  }
}
</script>
