const parisBudget = {
    assurance : 80,
    colocation : 500,
    appartment : 800,
    residence : 550,
    homeAssurance : 80,
    electricity : 50,
    communication : 20,
    transport : 75,
    food : 250,
    smallCost : 1003,
    confortCost : 1500
}

export default {
    parisBudget
}
