// sticky menu for mobiles
export function stickyAndScrollHelper() {
    window.onscroll = function() {stickMobileNav()};
    let mobileNav = document.getElementById("mobile-menu");
    let sticky = mobileNav.offsetTop;
    function stickMobileNav() {
      if (window.pageYOffset > sticky) {
        mobileNav.classList.add("sticky");
      } else {
        mobileNav.classList.remove("sticky");
      }
    }
    let mobileSelect = document.querySelector('#select-mobile-menu');
    let elementToScrollTo = document.querySelector('.scroll-to-element');
    mobileSelect.addEventListener('change', ()=>{
      elementToScrollTo.scrollIntoView({behavior: "smooth", block: "nearest"});
    })
}


// remplace le toggler de bootstrap
export function fakeCollapse(){
  document.addEventListener("click", function(event) {
    if (event.target.classList.contains("navbar-toggler-icon")) {
      document.querySelector(".navbar-collapse").classList.toggle("show");
    } else if (event.target.classList.contains("nav-link")) {
      document.querySelector(".navbar-collapse").classList.remove("show");
    }
  });
}