<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
            <router-link class="navbar-brand" to="/">
                Institut Hugo
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item">
                <router-link class="nav-link" to="/schools">Школы</router-link>
                </li>
                <li class="nav-item">
                <router-link class="nav-link" to="/services">Услуги</router-link>
                </li>
                <li class="nav-item">
                <router-link class="nav-link" to="/about">О нас</router-link>
                </li>
                <li class="nav-item">
                <router-link class="nav-link" to="/blog">Блог</router-link>
                </li>
                <li class="nav-item">
                <router-link class="nav-link" to="/contacts">Контакты</router-link>
                </li>
            </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import {fakeCollapse} from '@/helpers/Functions.js'
export default {
    mounted(){
        fakeCollapse()
    }
}
</script>