// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC646GzgS84225gp056r8dbBZ7FJdjSQDE",
  authDomain: "institut-hugo.firebaseapp.com",
  projectId: "institut-hugo",
  storageBucket: "institut-hugo.appspot.com",
  messagingSenderId: "1005960661734",
  databaseURL:"https://institut-hugo-default-rtdb.europe-west1.firebasedatabase.app/",
  appId: "1:1005960661734:web:f9a376ac2c7967a7750927"
};



// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(firebase, {
  provider: new ReCaptchaV3Provider('6LdKP7AdAAAAAJcuvf_Z7_wKc0PKiCl2Zkue9tjY'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});



export default firebase;
