<template>
    <section class="feedback">
        <div class="container">
            <div class="d-flex flex-column justify-content-center">
                <div class="row">
                    <div class="col-12">
                        <div class="h2-wrapper">
                            <h2>Отзывы</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="card-feedback">
                        <p class="feedback-text">
                            Виктория Еременко, IPAG - Grande Ecole Programme
                        </p>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="card-feedback text-of-feedback">
                            <p>
                                Я познакомилась с Таней и ее блогом совершенно случайно, когда находилась в поиске магистерской программы во французских бизнес-школах. 
                            </p>
                            <p>
                                В первую очередь, меня привлекло аккуратное оформление профиля и хорошо структурированная, подробная  информация о школах-партнерах, с которыми работает Таня.
                            </p>
                            <p>
                                На первом этапе мне было тяжело определиться, поэтому я написала Тане сообщение, чтобы получить дополнительную информацию о школах. 
                                Она сразу же предложила созвониться, чтобы я могла лучше описать ту школу мечты, которую я искала, а сразу же после телефонного звонка выслала мне список школ,
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="card-feedback text-of-feedback">
                            <p>
                                которые могли бы меня заинтересовать, и требования, которые необходимы для поступления.
                            </p>
                            <p>  
                                Когда выбор пал на одну из Таниных партнёрских бизнес-школ, началась активная совместная работа по подготовке моего досье. Таня всегда была на связи и помогала мне советами в оформлении резюме и мотивационного письма, подсказывала, что исправить или добавить.  
                            </p>
                            <p>    
                            Подача досье в выбранную мной школу открывалась только в начале декабря, а параллельно мне было необходимо подать документы на стипендию, я очень боялась не успеть.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="card-feedback text-of-feedback">
                            <p>
                                Тогда Таня связалась со своим контактным лицом, представителем моей школы, и согласовала досрочную подачу документов. После одобрения моего досье, мы с Таней провели пробное собеседование-симуляцию, которое очень помогло мне настроиться и побороть стресс во время реального собеседования. Теперь я зачислена на магистратуру в Париже и, с нетерпением ожидая следующего учебного года, хочу поблагодарить Таню за ее работу, участие, помощь и советы.
                                Если Вы собираетесь поступать в школы-партнеры Тани, то она проконсультирует и поможет Вам абсолютно бесплатно, а качеством ее услуг и профессионализмом Вы точно останетесь довольны! 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top:4rem">
                    <div class="col-12" >
                        <div class="d-flex flex-lg-row flex-column justify-content-start">
                            <div class="cta-wrapper">
                                <p>Задайте свои вопросы</p>
                                <a class="btn btn-light" href="https://t.me/tani_paris">Telegram</a>
                            </div>
                            <div class="cta-delimiter">

                            </div>
                            <div class="cta-wrapper">
                                <p>Вся полезная информация</p>
                                <a class="btn btn-light" href="https://www.instagram.com/tani.paris">Instagram</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>