<template>
    <section class="explore">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center" style="padding-bottom:3rem">
                    <h2>Образование</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-4">

                    <div class="card-program card-img-wrapper">
                        <div class="fond">
                            <div class="title-wrapper">
                                <router-link to="/tests/programs">
                                <p>Выбрать программу</p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-4">
                    <div class="card-city card-img-wrapper">
                        <div class="fond">
                            <div class="title-wrapper">
                                <router-link to="/cities">
                                    <p>Выбрать город</p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-4">
                    <div class="card-fee card-img-wrapper">
                        <div class="fond">
                            <div class="title-wrapper">
                                <router-link to="/cities">
                                <p>Рассчитать стоимость</p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>