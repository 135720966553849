import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import calculatedBudget from './helpers/Helpers'
import {getBrochureByPath} from '@/firebase/DownloadBrochure'


const application = createApp(App).use(store).use(router)

application.provide('$calculatedBudget', calculatedBudget);
application.config.globalProperties.$getBrochureByPath = getBrochureByPath;
application.mount('#app')

