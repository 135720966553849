<template>
    <section class="presentation">
        <div class="container h-100">
            <div class="row d-flex align-items-center" style="height:90%">
                <div class="col-12" style="position:relative">
                    <div class="watermark">
                        <img src="@/assets/Eiffel-Tower-Silhouette.svg" style="height:600px" alt="">
                    </div>
                    <div class="row" >
                        <div class="col-12 col-lg-5" style="z-index:100">
                            <div class="h1-wrapper">
                                <h1>Образование, работа и бизнес во Франции</h1>
                            </div>
                            <div class="h2-wrapper">
                                <h2 >Institut Hugo</h2>
                                <p>Ваш партнер по образованию и иммиграции.</p>
                            </div>
                            <div class="cta-wrapper d-flex">
                             
                                <div>
                                    <a class="btn btn-light btn-insta" href="https://instagram.com/tani.paris">
                                    Наш Instagram - 21k 
                                    <img class="user-icon" src='@/assets/instagram-white.svg' alt=""> 
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-12 col-lg-7">
                            <div class="d-flex flex-column justify-content-end h-100">
                                <div class="d-flex flex-row justify-content-lg-center figures">
                                    <div class="presentation-numbers">
                                        <p class="presentation-figures">10</p>
                                        <p class="presentation-details">Городов</p>
                                    </div>
                                    <div class="presentation-numbers">
                                        <p class="presentation-figures">300</p>
                                        <p class="presentation-details">Программ</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</template>