import firebase from './Firebase'
import {getBlob, ref, getStorage} from 'firebase/storage'

const storage = getStorage(firebase);

export async function getBrochureByPath(filePathString) { 
    await getBlob(ref(storage, '/brochures/'+filePathString))
    .then(filePdf =>{
        let file = new Blob([filePdf], {type: 'application/pdf'});
        let fileURL = URL.createObjectURL(file);
        this.$store.commit('updateDownloadLink', fileURL);
        window.open(fileURL);
        return fileURL;
    })
}

