<template>
    <section class="speciality">
        <div class="container-fluid readiness-wrapper">
            <div class="row d-flex flex-column h-100 justify-content-center">
                <div class="col-12 col-lg-4 offset-lg-4 div-cta-readiness">
                    <h3>Проверьте свою готовность</h3>
                    <router-link class="btn btn-light btn-test-ready" to="/tests/readiness">Пройти тест</router-link>
                </div>
            </div>
        </div>
        <div class="container speciality-container">
            <div class="row">
                <div class="col-12 text-center">
                    <h2 style="text-transform:uppercase">По специальности</h2>
                </div>
            </div>
            <div class="row" style="padding-top:3rem">
                <div class="col-12 col-lg-4">
                    <div class="card-business card-img-wrapper">
                        <div class="fond">
                            <div class="title-wrapper">
                                <p>
                                    <router-link class="link-speciality" to="/schools/business-schools">
                                    Бизнес школы
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="card-language card-img-wrapper">
                        <div class="fond">
                            <div class="title-wrapper">
                                <p>
                                    <router-link class="link-speciality" to="/schools/language-schools">
                                    Языковые школы
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="card-mode card-img-wrapper">
                        <div class="fond">
                            <div class="title-wrapper">
                                <p>
                                    <router-link class="link-speciality" to="/schools/fashion-schools">
                                    Школы моды и дизайна
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="padding-top:3rem">
                <div class="col-12 col-lg-4">
                    <div class="card-culinary card-img-wrapper">
                        <div class="fond">
                            <div class="title-wrapper">
                                <p>
                                    <router-link class="link-speciality" to="/schools/culinary-schools">
                                    Кулинарные школы
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="card-engineer card-img-wrapper">
                        <div class="fond">
                            <div class="title-wrapper">
                                <p>
                                    <router-link class="link-speciality" to="/schools/engineer-schools">
                                    Инженерные школы
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="card-web card-img-wrapper">
                        <div class="fond">
                            <div class="title-wrapper">
                                <p> 
                                    <router-link class="link-speciality" to="/schools/web-schools">
                                    Web/digital школы 
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>