import { createStore } from 'vuex'
import {writeUserData} from '@/firebase/SaveReadyness'

export default createStore({
  state: {
    englishLevel:'A1',
    frenchLevel:'B1',
    educationLeve: 'mid',
    certification: 1,
    diplomaLang: 'TODFL',
    certificationGmat: 0,
    person: {
      age:"",
      nationality:"",
      education:"",
      english:"",
      french:"",
      budget:"",
      name:"",
      phone:""
    },
    downloadLink: '',
    hasSubmitForm: false
  },
  mutations: {
    updatePerson(state, payloadPerson){
      for(let key in payloadPerson){
        
        if(key in state.person) {
          state.person[key] = payloadPerson[key]
        }
      }
      writeUserData(payloadPerson).then(()=>{
        state.hasSubmitForm = true
      });
      
    },
    updateDownloadLink(state, payloadUrlString){
      if(payloadUrlString){
        state.downloadLink = payloadUrlString;
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
